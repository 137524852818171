.modal {
  width: 50vw;
  position: absolute;
  top: 10%;
  left: 25%;
  background-color: lightgrey;
  border-radius: 15px;
  z-index: 2;
}

.modal h2 {
  margin-left: 15px;
}

.modal div {
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.modal button {
  margin: 0 15px 15px;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.67);
  backdrop-filter: blur(1px);
  z-index: 1;
}

.hidden {
  display: none;
}
