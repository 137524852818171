:root {
  --card-dim-ratio: 16 / 25; /* based on the background image */
  --card-height: 150px; /* scale everything by changing just this */
  --card-width: calc(var(--card-height) * var(--card-dim-ratio));
  --border-width: calc(var(--card-height) / 50);

  --card-info-width: calc(var(--card-width) * 9 / 32);

  --stack-vert-spacing: calc(var(--card-height) / 800);
  --stack-hori-spacing: calc(var(--card-width) / 16);
  --stack-hori-spacing-thin: calc(var(--card-width) / 194);

  --hand-spacing: calc(var(--card-info-width) * 11 / 10);
  
}
#root{
}
.card {
  height: var(--card-height);
  width: var(--card-width);

  /* picked to work well with background image's own border */
  border-style: solid;
  border-width: var(--border-width);
  border-radius: calc(var(--card-height) * 3 / 50);

  background-position: center;
}

/* types of cards */

.face-up {
  background-color: White;
}

.selected {
  border-color: Salmon;
  background-color: LightGrey;
}

.not-selected {
  border-color: #0b2652; /* attempt to match background picture */
}

.clickable:hover {
  cursor: pointer;
}

/* inside cards */

.card-info {
  width: var(--card-info-width);
  padding: calc(var(--card-height) / 100) 0;

  text-align: center;
  font-size: calc(var(--card-width) / 4);
}

.card-info img {
  width: calc(var(--card-width) * 3 / 16);
}

.red-suit .card-info {
  color: #85221b; /* attempt to match suit image colours */
}

/* groupings of cards */

.stack {
  display: flex;
  /* border: dotted 1px Black; */
  width: 50%;
  margin: 0 auto;
  /* margin: 0 auto; */
}

.hand {
  
  display: flex;
  width: 50%;
  margin: 0 auto;
  height: calc(var(--card-height) + (2 * var(--border-width)));
}

.deck {
  display: flex; /* puts both halves on the same row when cut */
  /* the max size that a full (52-card) deck takes up */
  height: calc(
    var(--card-height) + (2 * var(--border-width)) +
      (var(--stack-vert-spacing) * 51)
  );
  width: calc(
    (1 * var(--card-width)) + (2 * var(--border-width)) +
      (var(--stack-hori-spacing-thin) * 51)
  );
  
}
