:root {
  --max-width: 1600px;
  --min-width: 999px;
  --padding: 10px;
  background: #124c26;
  --score-board-boarder-width: 2px;
}
#root{
  background: #124c26;
}
.game-component {
  max-width: var(--max-width);
  min-width: var(--min-width);

  display: flex;
  margin: 0 auto;
  padding: var(--padding);
}

.board {
  max-width: 100%;
}

/* Some game components are divided into 4 columns */

.col {
  margin: 0 auto;
  padding: 0 var(--padding);
  width: 250px;
}

/* Specific game components */

/* todo CSS: may need to shrink action(s) below a certain threshold */

.options {
  background-color: DarkGrey;
  border-color: DarkGrey;
  display: none;
}

.headerbox {
  border-radius: 5px;
  border-style: solid;
  border-width: 5px;
  width: 250px;
}

.infobox {
  color: White;
  position: relative;
}

.dealer-icon {
  width: 2em;
  position: absolute;
  top: 1.3em;
  left: 0.5em;
}

.removeable:hover {
  cursor: pointer;
  opacity: 0.25;
  background: DarkGrey;
}

.addable {
  opacity: 0.25;
}

.addable:hover {
  cursor: pointer;
  opacity: 1;
  color: Black;
}

.plus {
  font-size: 60px;

  text-align: center;
}

.headerbox-info {
  text-align: center;
  margin: 5px 0;
}

.actions {
  height: 25px;
  align-items: center;
  justify-content: center;
}

.action {
  margin: 0 3px;
  padding: 3px;
  width: 150px;
}

.match-logs {
  flex-direction: column;
  height: 7em;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #124c26;
  border-radius: 5px;
  padding: 20px;
  color:aqua;
}
strong {
  color:blanchedalmond;
}
.message {
  text-align: left;
  padding: 3px;
}

/* scoreboard */

.board-row {
  display: flex;
}
