.editIcon {
  height: 0.9em;
}

.editIcon:hover {
  cursor: pointer;
}

.copy-code {
  cursor: copy;
}
